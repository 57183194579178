import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aries.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Aries
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aries"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aries</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm"></h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aries </h1>
                <h2 className="text-sm md:text-base ml-4">Mar 21 - Apr 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-love");
                  }}>
                  <h5 className="font-semibold text-black">Aries Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aries Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-man");
                  }}>
                  <h5 className="font-semibold text-black">Aries Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aries Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aries Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aries Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
              <h1 className="playfair text-black text-xl md:text-3xl">About Aries </h1>
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Sanskrit/Vedic name:
              </p>{" "}
              Mesha
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Aries Meaning
              </p>
              : The Ram
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Type:{" "}
              </p>
              Fire-Cardinal-Positive
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Aries Sign
              </p>
              <br />
              <br />
              Aries star sign is the foremost sign of the Zodiac and is also known as the infant of the zodiac circle. The sign expresses the Zodiac's birth and head and is one of the most dynamic zodiac signs. It gives a kick start to the whole zodiac wheel and the spring season.
              <br />
              <br />
              <p className="playfair text-black text-xl md:text-3xl">
                How to recognize Aries?
              </p>
              <br />
              The natives born on and between March 21 to April 19 belong to the Aries sun sign. The natives are born leaders, optimistic, courageous, lively, incredibly friendly people, firm in constructing a promise, and have an instant smile. Aries are known for their high-spirited zeal and morale. They are willing to be the hero of the action or event, fly away and carry many comprehensive risks.
              <br></br>
              <br></br>
              Hence, it is difficult to win over them in a statement/argument or take the lead in the discussion. They fight for mistreatment at the forefront and constantly come straight to the point. Aries natives are always keen to start things. They love to take on challenges and assemble impossible things happen. Their foremost aim is to be number one in all the missions and activities they participate in.
              <br />
              <br />
              Being the infant sign of the zodiac circle, purity is a default virtue that smoothens the egocentric mindset and contentiousness of the native. They are often bold in the untrodden path that permits them to emerge as the winner. Aries individuals also reveal positive energy and vitality. They are inherently charismatic, due to which they can persuade and show people effortlessly.
              <br></br>
              <br></br>
              Aries natives are loving mates and faithful in a relationship.
              <br></br>
              <br></br>
              Learn more about your relationship and how Aries maintain their love life. When the residents fall in love, whether men or women, they transmit their feelings without holding a second thought. The infant of the Zodiac is devoted and pampers their love partner and close ones broadly and limitlessly. Like a child, they desire the same level of love and affection from their half-mate, and if their partner fails to do so, they feel discouraged, dishearted, and disappointed. The natives try to comprehend and value their love partner but only till the time they get to expect love and attention from the partner
              <br></br>
              <br></br>
              But who is the ideal partner for the relationship with Aries individual? Know it with the help of the Free sun sign compatibility test
              <br>
              </br>
              <br />
              <p className="playfair text-black text-xl md:text-3xl">All About Aries</p>
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Domicile:
              </p>{" "}
              Mars
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Detriment:
              </p>{" "}
              Venus
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Exaltation:
              </p>{" "}
              The sun
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Fall:
              </p>
              Saturn
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Element:
              </p>
              Fire
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Quality:
              </p>
              Cardinal
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Symbol:
              </p>
              The Ram
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Tarot card:
              </p>{" "}
              Emperor
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Fellow signs:
              </p>{" "}
              Leo and Sagittarius <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Compatible signs:{" "}
              </p>
              Gemini, Leo, Sagittarius, and Aquarius
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Most Compatible Signs:{" "}
              </p>
              Leo
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Opposite Signs:
              </p>{" "}
              Libra and and Sometimes Capricorn
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                The Symbol denotes:
              </p>{" "}
              Positive, easygoing, arrogant, and confident behavior and an aggressive, rash, and impulsive approach.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Characteristics:{" "}
              </p>
              Masculine, optimistic, adventurous, attentive, devoted
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Noteworthy qualities:
              </p>{" "}
              Energetic, Diligence, enterprise, courage, dynamism, ambition, motivation, leadership, action orientation, and pioneering spirit.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Undesirable personality traits:{" "}
              </p>
              Audacity, impatience, carelessness, selfish and narcissistic approach, hastiness, recklessness.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Favorable things:
              </p>
              Competition, debates, adventurous trips and games, and shopping.
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Things they hate:
              </p>
              sharing their favored things, thoughtlessness, losing, listening to the word no
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Birthstone:
              </p>{" "}
              Red coral, Aquamarine
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky stones:{" "}
              </p>
              Ruby and diamond
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky Metals:
              </p>{" "}
              Iron, steel
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky day:{" "}
              </p>
              Tuesday
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lucky number:
              </p>{" "}
              6, 7
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Birth Colours:{" "}
              </p>
              All shades of red: scarlet, carmine, red, vermilion
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Deepest need:
              </p>{" "}
              Action
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Lifetime wish:
              </p>{" "}
              To be number one
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
                Mantra for life:{" "}
              </p>
              Forge Ahead
              <br />
              <br />
              <p className="font-bold text-orange-500 inline-block mr-2">
              The quality most needed for balance:{" "}
              </p>
              Patience and Caution
              <br />
              <br />
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
